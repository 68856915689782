import { useMutation } from "@apollo/client";

import {
  ADD_OWN_OEM_ASSET_3D_MODEL_MUTATION,
  ASSIGN_ASSETS_TO_PARENT_MUTATION,
  ASSIGN_MULTIPLE_ASSETS_TO_TEAM_MUTATION,
  ASSIGN_OWN_OEM_MULTIPLE_ASSETS_TO_OWN_OEM_CUSTOMER,
  ASSIGN_OWN_OEM_PARTS_TO_ASSET_MUTATION,
  CREATE_OWN_OEM_ASSET_MUTATION,
  DELETE_OEM_ASSET_MUTATION,
  DELETE_OWN_OEM_ASSET_3D_MODEL_MUTATION,
  DELETE_OWN_OEM_ASSET_IMAGE,
  GET_OWN_OEM_ASSET_BY_ID_QUERY,
  GET_OWN_OEM_CUSTOMER_BY_ID_QUERY,
  HANDLE_OWN_OEM_ASSET_QR_ACCESS_MUTATION,
  REMOVE_OWN_OEM_ASSET_FROM_OWN_OEM_CUSTOMER,
  REMOVE_OWN_OEM_PART_FROM_ASSET_MUTATION,
  UNASSIGN_ASSET_FROM_PARENT_MUTATION,
  UNASSIGN_ASSET_FROM_TEAM_MUTATION,
  UPDATE_OWN_OEM_ASSET_MUTATION,
} from "~/api";
import client from "~/apollo/_client";
import { errorsHandler } from "~/transformers";

export const updateAssetCache = (payload) => {
  const asset = client.readQuery({
    query: GET_OWN_OEM_ASSET_BY_ID_QUERY,
    variables: { id: payload._id },
  });

  if (!asset) return;

  const { getOwnOemAssetById: cachedAsset } = asset;
  if (cachedAsset) {
    const cloned = { ...cachedAsset };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_OEM_ASSET_BY_ID_QUERY,
      variables: { id: payload._id },
      data: {
        getOwnOemAssetById: cloned,
      },
    });
  }
};

export const useAssignAssetParts = () => {
  const [add, { loading, error }] = useMutation(
    ASSIGN_OWN_OEM_PARTS_TO_ASSET_MUTATION,
  );

  return {
    addNewParts: async (input) =>
      add({
        variables: { input },
        // @ts-ignore
        skip: !input?.assetId,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "getOwnOemAssetById",
          });
          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const useAssignAssetsToCustomer = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_OWN_OEM_MULTIPLE_ASSETS_TO_OWN_OEM_CUSTOMER,
  );

  return {
    error,
    assigningAssets: loading,
    mutation: (input) =>
      new Promise((resolve, reject) => {
        return assign({
          variables: { input },
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "getOwnOemCustomerById",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemAssets",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useAssignAssetsToParent = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_ASSETS_TO_PARENT_MUTATION,
  );

  return {
    mutation: ({
      assetIds,
      parentId,
    }: {
      assetIds: Array<string>;
      parentId: string;
    }) =>
      new Promise((resolve, reject) => {
        return assign({
          variables: {
            input: { assetIds, parentId },
          },
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "getOwnOemAssetById",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemAssets",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useAssignAssetsToTeam = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_MULTIPLE_ASSETS_TO_TEAM_MUTATION,
  );

  return {
    error,
    loading,
    mutation: (input) =>
      new Promise((resolve, reject) => {
        assign({
          variables: { input },
          // @ts-ignore
          skip: !input?.team,
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemAssets",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemCustomers",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemCustomers",
            });

            resolve(true);
          },
        })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useCreateAsset = () => {
  const [createOwnOemAsset, { error = {}, loading }] = useMutation(
    CREATE_OWN_OEM_ASSET_MUTATION,
  );

  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return createOwnOemAsset({
          variables: {
            input: {
              ...data,
            },
          },
          update: (cache) => {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemAssets",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemKanbanAssets",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useDeleteAsset = () => {
  const [remove, { loading, error }] = useMutation(DELETE_OEM_ASSET_MUTATION);

  return {
    loading,
    error,
    removeAsset: async (assetId: string) =>
      new Promise((resolve, reject) =>
        remove({
          variables: { assetId },
          // @ts-ignore
          skip: !assetId,
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemAssets",
            });
            cache.gc();
          },
          refetchQueries: [GET_OWN_OEM_CUSTOMER_BY_ID_QUERY],
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          }),
      ),
  };
};

export const useDeleteAssetImage = () => {
  const [remove, { loading, error }] = useMutation(DELETE_OWN_OEM_ASSET_IMAGE);

  return {
    loading,
    error,
    deleteImage: async (assetId) =>
      remove({
        variables: { assetId },
      }),
  };
};

export const useHandleAssetQRAccess = () => {
  const [handleOwnOemAssetQRAccess, { error = {}, loading }] = useMutation(
    HANDLE_OWN_OEM_ASSET_QR_ACCESS_MUTATION,
  );

  return {
    mutation: ({ assetId, isQRCodeEnabled }) =>
      new Promise((resolve, reject) => {
        return handleOwnOemAssetQRAccess({
          variables: {
            input: {
              assetId,
              isQRCodeEnabled,
            },
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useRemoveAssetFromCustomer = () => {
  const [remove, { loading, error }] = useMutation(
    REMOVE_OWN_OEM_ASSET_FROM_OWN_OEM_CUSTOMER,
  );

  return {
    loading,
    error,
    mutation: (input) =>
      new Promise((resolve, reject) => {
        return remove({
          variables: { input },
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "getOwnOemCustomerById",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemAssets",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useRemoveAssetPart = () => {
  const [remove, { loading, error }] = useMutation(
    REMOVE_OWN_OEM_PART_FROM_ASSET_MUTATION,
  );

  return {
    removePart: async (input) =>
      remove({
        variables: { input },
        // @ts-ignore
        skip: !input?.assetId || !input?.partId,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "getOwnOemAssetById",
          });
          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const useUnassignAssetFromParent = () => {
  const [unassign, { loading, error }] = useMutation(
    UNASSIGN_ASSET_FROM_PARENT_MUTATION,
  );

  return {
    mutation: ({ assetId, parentId }: { assetId: string; parentId: string }) =>
      new Promise((resolve, reject) => {
        return unassign({
          variables: { input: { assetId, parentId } },
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "getOwnOemAssetById",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemAssets",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useUnassignAssetFromTeam = () => {
  const [remove, { loading, error }] = useMutation(
    UNASSIGN_ASSET_FROM_TEAM_MUTATION,
  );

  return {
    loading,
    error,
    mutation: (input) =>
      new Promise((resolve, reject) => {
        return remove({
          variables: { input },
          // @ts-ignore
          skip: !input?.team || !input?.asset,
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemAssets",
            });

            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemAllTickets",
            });
            cache.gc();
          },
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useUpdateAsset = () => {
  const [updateOwnOemAsset, { error = {}, loading }] = useMutation(
    UPDATE_OWN_OEM_ASSET_MUTATION,
  );

  return {
    mutation: (data, evictCustomerCache = false) =>
      new Promise((resolve, reject) => {
        return updateOwnOemAsset({
          variables: {
            input: {
              ...data,
            },
          },
          update(cache) {
            if (evictCustomerCache)
              cache.evict({
                id: "ROOT_QUERY",
                fieldName: "getOwnOemCustomerById",
              });

            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemAssets",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemAllTickets",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useDeleteAsset3DModel = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_OWN_OEM_ASSET_3D_MODEL_MUTATION,
  );

  return {
    loading,
    error,
    deleteAsset3DModel: async (assetId) =>
      remove({
        variables: { assetId },
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemAssets",
          });
          cache.gc();
        },
      }),
  };
};

export const useAddAsset3DModel = () => {
  const [add, { loading, error }] = useMutation(
    ADD_OWN_OEM_ASSET_3D_MODEL_MUTATION,
  );

  return {
    loading,
    error,
    addAsset3DModel: async ({ _id, _3dModelUrl }) =>
      add({
        variables: { id: _id, input: _3dModelUrl },
      }),
  };
};
