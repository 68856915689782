import { useMutation } from "@apollo/client";

import {
  ASSIGN_MULTIPLE_CUSTOMERS_TO_TEAM_MUTATION,
  ASSIGN_MULTIPLE_MACHINES_TO_TEAM_MUTATION,
  ASSIGN_MULTIPLE_PRODUCTION_LINES_TO_TEAM_MUTATION,
  ASSIGN_MULTIPLE_USERS_TO_TEAM_MUTATION,
  CREATE_TEAM_MUTATION,
  DELETE_TEAM_MUTATION,
  Team,
  UNASSIGN_CUSTOMER_FROM_TEAM_MUTATION,
  UNASSIGN_MACHINE_FROM_TEAM_MUTATION,
  UNASSIGN_PRODUCTION_LINE_FROM_TEAM_MUTATION,
  UNASSIGN_USER_FROM_TEAM_MUTATION,
  UPDATE_TEAM_MUTATION,
} from "~/api";
import client from "~/apollo/_client";
import { errorsHandler } from "~/transformers";

export const useCreateTeam = () => {
  const [create, { error = {}, loading }] = useMutation(CREATE_TEAM_MUTATION);

  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return create({
          variables: {
            input: {
              ...data,
            },
          },
          update: (cache) => {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listTeams",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useDeleteTeam = () => {
  const [remove, { loading, error }] = useMutation(DELETE_TEAM_MUTATION);

  return {
    loading,
    error,
    deleteTeam: async (id) =>
      new Promise((resolve, reject) =>
        remove({
          variables: { id },
          skip: !id,
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listTeams",
            });

            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemSupportAccounts",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemAllTickets",
            });

            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemCustomers",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemProductionLines",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemMachines",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "useListAllOwnOemAssets",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          }),
      ),
  };
};

export const useUpdateTeam = () => {
  const [updateTeam, { error = {}, loading }] =
    useMutation(UPDATE_TEAM_MUTATION);

  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return updateTeam({
          variables: {
            input: {
              ...data,
            },
          },
          update(cache, { data: { updateTeam: ref } }) {
            cache.modify({
              id: cache.identify({
                __typename: "Team",
                ref: ref._id,
              }),
              fields: {
                getTeam() {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: Team.fragments.TeamData,
                    fragmentName: "TeamData",
                  });

                  return newRef;
                },
              },
            });
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useAssignMultipleMachinesToTeam = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_MULTIPLE_MACHINES_TO_TEAM_MUTATION,
  );

  return {
    error,
    assigningMachines: loading,
    assignMultipleMachinesToTeam: async (input) =>
      assign({
        variables: { input },
        skip: !input?.team,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemAllTickets",
          });
          cache.gc();
        },
      }),
  };
};

export const useAssignMultipleProductionLinesToTeam = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_MULTIPLE_PRODUCTION_LINES_TO_TEAM_MUTATION,
  );

  return {
    error,
    assigningProductionLines: loading,
    assignMultipleProductionLinesToTeam: async (input) =>
      assign({
        variables: { input },
        skip: !input?.team,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemProductionLines",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemAllTickets",
          });
          cache.gc();
        },
      }),
  };
};

export const useAssignMultipleCustomersToTeam = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_MULTIPLE_CUSTOMERS_TO_TEAM_MUTATION,
  );

  return {
    error,
    assigningCustomers: loading,
    assignMultipleCustomersToTeam: async (input) =>
      assign({
        variables: { input },
        skip: !input?.team,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemCustomers",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemProductionLines",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemAllTickets",
          });
          cache.gc();
        },
      }),
  };
};

export const useAssignMultipleUsersToTeam = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_MULTIPLE_USERS_TO_TEAM_MUTATION,
  );

  return {
    error,
    assigningUsers: loading,
    assignMultipleUsersToTeam: async (input) =>
      assign({
        variables: { input },
        skip: !input?.team,
        update() {
          updateCacheOnTeamChange();
        },
      }),
  };
};

export const useUnassignProductionLineFromTeam = () => {
  const [remove, { loading, error }] = useMutation(
    UNASSIGN_PRODUCTION_LINE_FROM_TEAM_MUTATION,
  );

  return {
    loading,
    error,
    unassignProductionLineFromTeam: async (input) =>
      remove({
        variables: { input },
        skip: !input?.team || !input?.productionLine,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemProductionLines",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemAllTickets",
          });
          cache.gc();
        },
      }),
  };
};

export const useUnassignCustomerFromTeam = () => {
  const [remove, { loading, error }] = useMutation(
    UNASSIGN_CUSTOMER_FROM_TEAM_MUTATION,
  );

  return {
    loading,
    error,
    unassignCustomerFromTeam: async (input) =>
      remove({
        variables: { input },
        skip: !input?.team || !input?.customer,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemCustomers",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemProductionLines",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemAllTickets",
          });
          cache.gc();
        },
      }),
  };
};

export const useUnassignMachineFromTeam = () => {
  const [remove, { loading, error }] = useMutation(
    UNASSIGN_MACHINE_FROM_TEAM_MUTATION,
  );

  return {
    loading,
    error,
    unassignMachineFromTeam: async (input) =>
      remove({
        variables: { input },
        skip: !input?.team || !input?.machine,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemAllTickets",
          });
          cache.gc();
        },
      }),
  };
};

export const useUnassignUserFromTeam = () => {
  const [remove, { loading, error }] = useMutation(
    UNASSIGN_USER_FROM_TEAM_MUTATION,
  );

  return {
    loading,
    error,
    unassignUserFromTeam: async (input) =>
      remove({
        variables: { input },
        skip: !input?.team || !input?.user,
        update() {
          updateCacheOnTeamChange();
        },
      }),
  };
};

export const updateCacheOnTeamChange = () => {
  client.cache.evict({
    id: "ROOT_QUERY",
    fieldName: "listOwnOemSupportAccounts",
  });
  client.cache.evict({
    id: "ROOT_QUERY",
    fieldName: "listOwnOemTickets",
  });
  client.cache.evict({
    id: "ROOT_QUERY",
    fieldName: "listOwnUserSchedules",
  });
  client.cache.evict({
    id: "ROOT_QUERY",
    fieldName: "listUsersCalendarEvents",
  });
  client.cache.evict({
    id: "ROOT_QUERY",
    fieldName: "getOwnTicketScheduleDraft",
  });
  client.cache.gc();
};
