/* eslint-disable camelcase */

import { ITEMS_PER_PAGE } from "#/src/constants";
import getEnums from "$/settings/enums";
import TICKET_TYPES from "$/settings/enums/ticket/_types.json";
import {
  ASSIGNEE,
  DATE_RANGE,
  FACILITY_NAME,
  ASSET_NAME,
  SCHEDULED,
  TEAMS,
  TICKET_STATUS,
  TICKET_TYPE,
} from "~/constants/filters/filters";

const TICKET_TYPE_ENUMS = getEnums(TICKET_TYPES, "reference");

const defaultFilters = {
  [TICKET_STATUS]: [],
  [ASSIGNEE]: [],
  [TICKET_TYPE]: [],
  [FACILITY_NAME]: [],
  [ASSET_NAME]: [],
  [DATE_RANGE]: [],
  [SCHEDULED]: [],
  [TEAMS]: [],
};

const applyFilters = (activeFilters = defaultFilters, _filter, _ticketType) => {
  if (_ticketType) {
    if (activeFilters[_filter]?.length > 0) {
      if (activeFilters[_filter]?.includes(TICKET_TYPE_ENUMS.ServiceRequest)) {
        return [undefined, ...activeFilters[_filter]]; // undefined is to treat documents without ticketType field as "Service Request" ticket type.
      }
      return activeFilters[_filter];
    } else {
      return undefined;
    }
  } else {
    if (activeFilters[_filter]?.length > 0) {
      return activeFilters[_filter];
    } else {
      return undefined;
    }
  }
};

export const generateOpenTicketQueryWhereCond = (
  activeFilters = defaultFilters,
) => {
  const customFields = [];

  for (const filter of Object.keys(activeFilters)) {
    if (!defaultFilters[filter] && activeFilters[filter]?.length) {
      customFields.push({
        fieldId: filter,
        value: activeFilters[filter],
      });
    }
  }
  const assignees = applyFilters(activeFilters, ASSIGNEE);
  const status = applyFilters(activeFilters, TICKET_STATUS);
  const facility_in = applyFilters(activeFilters, FACILITY_NAME);
  const machine_in = applyFilters(activeFilters, ASSET_NAME);
  const scheduled = applyFilters(activeFilters, SCHEDULED);
  const ticketType_in = applyFilters(activeFilters, TICKET_TYPE, true);
  const date = applyFilters(activeFilters, DATE_RANGE); // here the field should be date not created_at
  const teams_in = applyFilters(activeFilters, TEAMS);

  return {
    ...(assignees ? { assignees } : {}),
    ...(status ? { status } : {}),
    ...(facility_in ? { facility_in } : {}),
    ...(machine_in ? { machine_in } : {}),
    ...(scheduled ? { scheduled } : {}),
    ...(ticketType_in ? { ticketType_in } : {}),
    ...(date ? { date } : {}),
    ...(teams_in ? { teams_in } : {}),
    ...(customFields?.length ? { customFields } : {}),
  };
};

export const generateListOwnOemTicketsVariables = ({
  activeFilters,
  where,
  searchQuery,
  limit = ITEMS_PER_PAGE,
  skip = 0,
  sort = [],
}) => {
  const filterConditions = generateOpenTicketQueryWhereCond(activeFilters);
  const hasFilter = Object.keys(filterConditions)?.length;
  const whereCondition = hasFilter
    ? { ...filterConditions, searchQuery, ...(where || {}) }
    : { searchQuery, ...(where || {}) };

  return {
    params: {
      limit,
      skip,
      sort,
      where: whereCondition,
    },
  };
};
