import { gql } from "@apollo/client";

export const User = {
  fragments: {
    UserBasicData: gql`
      fragment UserBasicData on User {
        _id
        name
        email
        info
        access
        userCredentialsSent
        isOem
        emailNotification
        chatToken
        chatKeys
        chatUUID
        callChannels
        role
        skills {
          _id
        }
        hasAiAssistantAccess
        notification {
          email {
            onNewTicketAssigned
            onAssignedTicketInternalNotePost
            onAssignedWorkOrderUpdate
            onAddedAsWorkOrderFollower
            onMentionedInWorkOrderInternalNote
            onMessageOnAssignedWorkOrder
            onAssignedWorkOrderReminder {
              enabled
              daysBefore
            }
          }
        }
        firstName
        lastName
        department
        function
        phone
        mobile
        deleted
        teams {
          _id
          name
          teamColor
        }
      }
    `,
    UserAuthData: gql`
      fragment UserAuthData on User {
        _id
        foldersAccessToken
        fileImporter {
          _id
          licenseKey
          user
          importers {
            fields
            settings
            meta {
              templateId
              templateSection
            }
          }
        }
        name
        intercomHash
        email
        emailSignature
        info
        access
        userCredentialsSent
        isOem
        emailNotification
        chatToken
        chatKeys
        chatUUID
        callChannels
        role
        notification {
          email {
            onNewTicketAssigned
            onAssignedTicketInternalNotePost
            onAssignedWorkOrderUpdate
            onAddedAsWorkOrderFollower
            onMentionedInWorkOrderInternalNote
            onMessageOnAssignedWorkOrder
            onAssignedWorkOrderReminder {
              enabled
              daysBefore
            }
          }
        }
        notificationChannelGroupName
        notificationChannel
        organizationNotificationChannel
        boards {
          boardFor
          customFields {
            _id
            label
            options {
              value
              color
              _id
            }
          }
        }
        hasAiAssistantAccess
        calendarSyncAccountLinked
        calendarSyncAccountStatus
        linkedCalendarSyncEmailAddress
        consumedRecordingSeconds
      }
    `,
  },
};

export const WorkOrderType = {
  fragments: {
    WorkOrderTypeData: gql`
      fragment WorkOrderTypeData on TicketType {
        _id
        name
        color
        icon
        isInternal
        isSystem
        partsConfig {
          show
          isRequired
          fieldName
        }
        descriptionConfig {
          show
          isRequired
          fieldName
        }
        customFields {
          _id
          isRequired
          description
          customAdditionalField {
            _id
            type
            label
            fieldType
            options {
              _id
              value
              color
            }
          }
        }
      }
    `,
  },
};

export const Oem = {
  fragments: {
    AiAssistantConfiguration: gql`
      fragment AiAssistantConfiguration on Oem {
        aiAssistantConfiguration {
          allowedQueries
          allowedRecordingSeconds
          allowedStorage
          allowedUsers
        }
      }
    `,
    AiAssistantUsage: gql`
      fragment AiAssistantUsage on Oem {
        aiAssistantUsage {
          consumedQueries
          consumedStorage
          consumedUsers
        }
      }
    `,
    OemBasicData: gql`
      fragment OemBasicData on Oem {
        _id
        logo
        thumbnail
        backgroundColor
        brandLogo
        heading
        subHeading
        paragraph
        textColor
        name
        urlOemFacility
        slug
        allowFollowersMyWorkOrders
        allowAssigneesAcrossTeams
        statuses {
          _id
          label
          color
        }
        skills {
          _id
          name
        }
        ticketTypes {
          ...WorkOrderTypeData
        }
        notification {
          email {
            notifyOnWorkOrderCreation
            workOrderCreationNotifyTo {
              user {
                _id
                name
                email
              }
              workOrderTypes
            }
            notifyOnMaintenanceWorkOrderCreation
            maintenanceWorkOrderCreationNotifyTo {
              _id
              name
            }
            notifyOnMessageOnUnassignedWorkOrder
            messageOnUnassignedWorkOrderNotifyTo {
              _id
              name
            }
          }
        }
      }
      ${WorkOrderType.fragments.WorkOrderTypeData}
    `,
  },
};

export const CustomizeField = {
  fragments: {
    AdditionFieldData: gql`
      fragment AdditionFieldData on AdditionalField {
        _id
        type
        slug
        label
        fieldType
        isAdditionalField
        enabled
      }
    `,

    CustomAdditionFieldData: gql`
      fragment CustomAdditionFieldData on CustomAdditionalField {
        _id
        type
        slug
        label
        fieldType
        isAdditionalField
        enabled
        created_at
        order
        oem {
          _id
          name
        }
        options {
          _id
          value
          color
        }
      }
    `,
  },
};

export const MachineBasicData = gql`
  fragment MachineBasicData on Machine {
    _id
    name
    serialNumber
    image
    thumbnail
    description
    slug
    oem {
      logo
      thumbnail
    }
    teams {
      _id
      name
    }
  }
`;

export const Customer = {
  fragments: {
    CustomerBasicData: gql`
      fragment CustomerBasicData on Customer {
        _id
        name
        isQRCodeEnabled
        totalUsersWithAccess
        totalUsers
        facilityId
        createdAt
        updatedAt
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
        teams {
          _id
          name
          teamColor
        }
      }
      ${CustomizeField.fragments.CustomAdditionFieldData}
    `,
    CustomerFullData: gql`
      fragment CustomerFullData on Customer {
        _id
        facilityId
        name
        isQRCodeEnabled
        qrCodeAccess
        isProductionLineDocumentationEnabled
        isMachineDocumentationEnabled
        isPreventiveMaintenanceEventsEnabled
        generalAccessUrl
        totalMachines
        totalUsersWithAccess
        totalUsers
        createdBy
        createdAt
        description
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
        teams {
          _id
          name
          teamColor
        }
      }
      ${CustomizeField.fragments.CustomAdditionFieldData}
    `,
  },
};

export const Machine = {
  fragments: {
    MachineData: gql`
      fragment MachineData on Machine {
        _id
        customer {
          _id
          name
          facilityId
        }
        slug
        name
        serialNumber
        image
        thumbnail
        description
        createdBy
        oem {
          _id
          name
          slug
        }
        template {
          _id
          title
          type
          image
          thumbnail
        }
        _3dModelUrl
        _3dModelUrlUploadedBy {
          _id
          name
        }
        detachedFromTemplate {
          image
          description
          inventoryParts
        }
      }
    `,
    MachineFullData: gql`
      fragment MachineFullData on Machine {
        _id
        slug
        aiAssistant {
          _id
          assistantName
        }
        oem {
          _id
          name
          slug
          statuses {
            _id
            label
            color
          }
          logo
          thumbnail
          slug
        }
        customer {
          _id
          name
          facilityId
        }
        productionLine {
          _id
          name
          customer {
            _id
            name
          }
        }
        name
        detachedFromTemplate {
          image
          description
          inventoryParts
          documentation
        }
        folderId
        serialNumber
        isBoxFoldersDisabled
        isQRCodeEnabled
        generalAccessUrl
        customFields {
          _id
          fieldId {
            _id
            type
            label
            fieldType
            isAdditionalField
            enabled
            created_at
            order
            options {
              _id
              value
              color
            }
          }
          value
        }
        image
        thumbnail
        description
        template {
          _id
          title
          type
          image
          thumbnail
          description
          documentFolders {
            internalId
            externalId
          }
        }
        createdAt
        createdBy
        documentFolders {
          internalId
          externalId
        }
        teams {
          _id
          name
          teamColor
        }
        _3dModelUrl
        _3dModelUrlUploadedBy {
          _id
          name
        }
      }
    `,
    MachinePartsData: gql`
      fragment MachinePartsData on Machine {
        _id
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
            oem {
              _id
              name
            }
            customFields {
              _id
              fieldId {
                _id
                type
                label
                fieldType
                isAdditionalField
                enabled
                created_at
                order
                options {
                  _id
                  value
                  color
                }
              }
              value
            }
          }
        }
      }
    `,
    MachineListData: gql`
      fragment MachineListData on Machine {
        _id
        name
        serialNumber
        image
        createdAt
        updatedAt
        thumbnail
        generalAccessUrl
        isQRCodeEnabled
        teams {
          _id
          name
          teamColor
        }
        productionLine {
          _id
          name
          lineId
          customer {
            _id
            name
          }
        }
        customer {
          _id
          name
          customFields {
            value
          }
        }
        customFields {
          _id
          fieldId {
            _id
            type
            label
            fieldType
            isAdditionalField
            enabled
            created_at
            order
            options {
              _id
              value
              color
            }
          }
          value
        }
        createdBy
        template {
          _id
          title
          type
          image
          thumbnail
        }
        oem {
          _id
          logo
          thumbnail
        }
        _3dModelUrlUploadedBy {
          _id
          name
        }
        isMachine3dModelDeletable
      }
    `,
  },
};

export const ProductionLine = {
  fragments: {
    ProductionLineData: gql`
      fragment ProductionLineData on ProductionLine {
        _id
        oem {
          _id
          logo
          thumbnail
          name
        }
        customer {
          _id
          name
          isQRCodeEnabled
          facilityId
        }
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
        name
        lineId
        description
        createdAt
        createdBy
        teams {
          _id
          name
          teamColor
        }
        documentFolders {
          internalId
          externalId
        }
      }
      ${CustomizeField.fragments.CustomAdditionFieldData}
    `,

    ProductionLineListData: gql`
      fragment ProductionLineListData on ProductionLine {
        _id
        customer {
          _id
          name
        }
        numberOfMachines
        name
        lineId
        description
        createdAt
        createdBy
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
        teams {
          _id
          name
          teamColor
        }
      }
      ${CustomizeField.fragments.CustomAdditionFieldData}
    `,
  },
};

export const Resource = {
  fragments: {
    ResourceData: gql`
      fragment ResourceData on Resource {
        _id
        name
        resourceId
        ticketsCount
        type
      }
    `,
    ResourceListData: gql`
      fragment ResourceListData on Resource {
        _id
        name
        resourceId
        ticketsCount
        type
      }
    `,
  },
};

export const TicketScheduleDraft = {
  fragments: {
    TicketScheduleDraftData: gql`
      fragment TicketScheduleDraftData on TicketScheduleDraft {
        _id
        draft {
          operationType
          ticket {
            id
            assignees
            resources
            schedule {
              startTime
              endTime
              startTimezone
              endTimezone
              isAllDay
              isTimezoneEnabled
            }
            status
          }
          unavailable {
            id
            user
            schedule {
              startTime
              endTime
              startTimezone
              endTimezone
              isAllDay
              isTimezoneEnabled
            }
            scheduleType
            toDelete
          }
        }
        version
      }
    `,
  },
};

export const UserSchedule = {
  fragments: {
    UserScheduleData: gql`
      fragment UserScheduleData on UserSchedule {
        _id
        schedule {
          startTime
          endTime
          startTimezone
          endTimezone
          isAllDay
          isTimezoneEnabled
        }
        scheduleType
        user
        uuid
      }
    `,
  },
};

export const AiNote = {
  fragments: {
    AiNoteBasicData: gql`
      fragment AiNoteBasicData on AiNote {
        _id
        title
        createdAt
      }
    `,
    AiNoteData: gql`
      fragment AiNoteData on AiNote {
        _id
        audioUrl
        createdAt
        createdBy {
          _id
          name
        }
        publishedMachines {
          machine {
            _id
            image
            name
            serialNumber
          }
          machineHistoryNoteId
          publishedAt
          publishedBy
        }
        summary
        title
        transcript
      }
    `,
  },
};

export const Component = {
  fragments: {
    ComponentData: gql`
      fragment ComponentData on Component {
        _id
        oem {
          _id
          logo
          thumbnail
          name
        }
        machine {
          ...MachineBasicData
        }
        name
        componentId
        description
        createdAt
        createdBy
        image
        thumbnail
      }
      ${MachineBasicData}
    `,
    ComponentDetailData: gql`
      fragment ComponentDetailData on Component {
        _id
        oem {
          _id
          logo
          thumbnail
          name
        }
        machine {
          ...MachineBasicData
        }
        name
        componentId
        description
        createdAt
        createdBy
        image
        thumbnail
        documentFolders {
          internalId
          externalId
        }
      }
      ${MachineBasicData}
    `,
    ComponentPartsData: gql`
      fragment ComponentPartsData on Component {
        _id
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
          }
        }
      }
    `,
  },
};

export const Schedule = {
  fragments: {
    TicketScheduleData: gql`
      fragment TicketScheduleData on Ticket {
        schedule {
          startTime
          endTime
          startTimezone
          endTimezone
          isAllDay
          isTimezoneEnabled
        }
      }
    `,
  },
};

export const Ticket = {
  fragments: {
    TicketData: gql`
      fragment TicketData on Ticket {
        _id
        ticketId
        title
        description
        status
        url
      }
    `,
    TicketListData: gql`
      fragment TicketListData on Ticket {
        _id
        ticketId
        title
        status
        ticketType {
          _id
          name
          icon
          color
          isInternal
          isSystem
        }
        createdAt
        createdBy {
          _id
          name
        }
        updatedAt
        maintenanceOn
        hasOriginalEventBeenDeleted
        assignees {
          _id
          name
        }
        followers {
          _id
        }
        machine {
          _id
          name
          serialNumber
        }
        facility {
          _id
          name
          customFields {
            _id
            fieldId {
              _id
              slug
            }
            value
          }
        }
        ticketChatChannels
        ticketInternalNotesChatChannels
        customFields {
          fieldId {
            _id
          }
          value
        }
        teams {
          _id
          name
          teamColor
        }
        procedures {
          procedure {
            _id
          }
        }
        user {
          _id
          name
        }
        resources {
          _id
        }
        ...TicketScheduleData
      }
      ${Schedule.fragments.TicketScheduleData}
    `,

    TicketSingleData: gql`
      fragment TicketSingleData on Ticket {
        _id
        ticketId
        ticketType {
          _id
          name
          icon
          color
          isInternal
          isSystem
          descriptionConfig {
            show
            isRequired
            fieldName
          }
        }
        linkedTickets {
          ticket {
            _id
            ticketId
            ticketType {
              _id
              name
              icon
              color
              isInternal
              isSystem
              descriptionConfig {
                show
                isRequired
                fieldName
              }
            }
            title
            status
            facility {
              name
            }
            machine {
              name
            }
          }
          linkedBy {
            _id
          }
          linkedAt
        }
        title
        description
        status
        notes
        url
        createdAt
        createdBy {
          _id
          name
        }
        updatedAt
        lastUpdatedAt
        maintenanceOn
        hasOriginalEventBeenDeleted
        teams {
          _id
          name
          teamColor
          members {
            _id
            name
          }
        }
        assignees {
          ...UserBasicData
        }
        followers {
          ...UserBasicData
        }
        user {
          ...UserBasicData
        }
        machine {
          ...MachineFullData
        }
        facility {
          ...CustomerBasicData
        }
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
        ticketChatChannels
        ticketInternalNotesChatChannels
        inventoryParts {
          quantity
          addedBy {
            _id
            role
          }
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
            oem {
              _id
              thumbnail
            }
          }
        }
        timeTrackerLogs {
          _id
          description
          startDateTime
          endDateTime
          isBillable
          timeElapsedInSeconds
          ticketTag {
            _id
            label
            color
          }
          createdBy {
            _id
            name
          }
        }
        totalTimeLoggedInSeconds
        procedures {
          procedure {
            _id
            name
            createdAt
            state
            procedureId
          }
          addedBy {
            name
            _id
          }
        }
        resources {
          _id
        }
        ...TicketScheduleData
      }
      ${User.fragments.UserBasicData}
      ${Machine.fragments.MachineFullData}
      ${Customer.fragments.CustomerBasicData}
      ${CustomizeField.fragments.CustomAdditionFieldData}
      ${Schedule.fragments.TicketScheduleData}
    `,
  },
};

export const CallInfo = {
  fragments: {
    CallInfoData: gql`
      fragment CallInfoData on CallInfo {
        _id
        uid
        token
        channel
        caller
        receiver
      }
    `,
  },
};

export const PreventiveMaintenance = {
  fragments: {
    PreventiveEventData: gql`
      fragment PreventiveEventData on PreventiveMaintenance {
        _id
        title
        startDate
        eventDate
        frequency
        repeatInNumber
        repeatIn
        ticketCreationNumber
        ticketCreationIn
        description
        nextCreationDate
        procedures {
          procedure {
            _id
            name
          }
        }
        inventoryParts {
          part {
            _id
            name
            image
          }
          quantity
        }
        createdBy {
          _id
        }
        oem {
          _id
        }
        machine {
          _id
        }
      }
    `,
    PreventiveEventDetailedData: gql`
      fragment PreventiveEventDetailedData on PreventiveMaintenance {
        _id
        title
        startDate
        eventDate
        frequency
        repeatInNumber
        repeatIn
        ticketCreationNumber
        ticketCreationIn
        description
        nextCreationDate
        createdBy {
          _id
          name
          email
        }
        oem {
          _id
        }
        machine {
          _id
          name
        }
        procedures {
          procedure {
            _id
            name
          }
          addedBy {
            name
            _id
          }
        }
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
          }
          quantity
        }
      }
    `,
  },
};

export const MachineTemplate = {
  fragments: {
    MachineTemplateData: gql`
      fragment MachineTemplateData on MachineTemplate {
        _id
        templateId
        title
        type
        description
        image
        thumbnail
        createdBy
        oem {
          ...OemBasicData
        }
        machines {
          _id
        }
      }
      ${Oem.fragments.OemBasicData}
    `,
    MachineTemplateFullData: gql`
      fragment MachineTemplateFullData on MachineTemplate {
        _id
        templateId
        title
        type
        description
        image
        thumbnail
        createdBy
        oem {
          ...OemBasicData
        }
        documentFolders {
          internalId
          externalId
        }
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
          }
        }
      }
      ${Oem.fragments.OemBasicData}
    `,
  },
};

export const InventoryPart = {
  fragments: {
    InventoryPartBasicData: gql`
      fragment InventoryPartBasicData on InventoryPart {
        _id
        name
        articleNumber
        description
        image
        thumbnail
        createdBy
        createdAt
        oem {
          ...OemBasicData
        }
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
      }
      ${Oem.fragments.OemBasicData}
      ${CustomizeField.fragments.CustomAdditionFieldData}
    `,
    InventoryPartFullData: gql`
      fragment InventoryPartFullData on InventoryPart {
        _id
        name
        articleNumber
        description
        image
        thumbnail
        createdBy
        oem {
          ...OemBasicData
        }
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
        documentFolders {
          internalId
          externalId
        }
      }
      ${Oem.fragments.OemBasicData}
      ${CustomizeField.fragments.CustomAdditionFieldData}
    `,
  },
};

export const TimeTracker = {
  fragments: {
    TimeTrackerBasicData: gql`
      fragment TimeTrackerBasicData on TimeTracker {
        _id
        type
        slug
        label
        fieldType
        color
      }
    `,
  },
};

const ProcedureTemplateChildrenNode = {
  fragments: {
    ProcedureTemplateChildren: gql`
      fragment ProcedureTemplateChildren on ProcedureTemplateNode {
        _id
        name
        type
        description
        isRequired
        attachments {
          _id
          name
          type
          url
          size
        }
        options {
          _id
          name
        }
        tableOption {
          _id
          columns {
            _id
            heading
            width
          }
          rowCount
        }
      }
    `,
  },
};
const ProcedureInstanceChildrenNode = {
  fragments: {
    ProcedureInstanceChildren: gql`
      fragment ProcedureInstanceChildren on ProcedureNode {
        _id
        name
        type
        description
        isRequired
        value
        attachments {
          _id
          name
          type
          url
          size
        }
        options {
          _id
          name
        }
        tableOption {
          _id
          columns {
            _id
            heading
            width
          }
          rowCount
        }
      }
    `,
  },
};
export const ProcedureTemplate = {
  fragments: {
    ProcedureTemplateFullData: gql`
      fragment ProcedureTemplateFullData on ProcedureTemplate {
        _id
        name
        description
        createdAt
        updatedAt
        pageHeader {
          name
          size
          type
          url
        }
        createdBy {
          _id
          name
        }
        signatures {
          _id
          signatoryTitle
        }
        children {
          ...ProcedureTemplateChildren
          children {
            ...ProcedureTemplateChildren
          }
        }
      }
      ${ProcedureTemplateChildrenNode.fragments.ProcedureTemplateChildren}
    `,
  },
};
export const ProcedureInstance = {
  fragments: {
    ProcedureInstanceFullData: gql`
      fragment ProcedureInstanceFullData on Procedure {
        _id
        name
        state
        description
        createdAt
        updatedAt
        pdfUrl
        procedureId
        signatures {
          _id
          signatoryTitle
          name
          date
          signatureUrl
        }
        submittedBy {
          _id
          name
        }
        children {
          ...ProcedureInstanceChildren
          children {
            ...ProcedureInstanceChildren
          }
        }
      }
      ${ProcedureInstanceChildrenNode.fragments.ProcedureInstanceChildren}
    `,
  },
};

export const Team = {
  fragments: {
    TeamData: gql`
      fragment TeamData on Team {
        _id
        oem {
          _id
          name
        }
        name
        description
        teamColor
      }
    `,
  },
};

export const Reports = {
  fragments: {
    ReportData: gql`
      fragment ReportData on Report {
        _id
        title
        filters {
          field
          condition
          value {
            value
            label
          }
        }
        xAxis {
          value
          label
        }
        segment {
          value
          label
        }
        chartType
        table {
          value
          label
        }
        entity
      }
    `,
  },
};
export const MachineHistory = {
  fragments: {
    MachineHistoryData: gql`
      fragment MachineHistoryData on MachineHistory {
        year
        type
        resourceId
        resource
        note {
          message
          isInternal
          attachments {
            url
            type
            size
            name
            createdAt
          }
        }
        month
        machine
        _id
        createdAt
        createdBy {
          name
          _id
        }
        customer {
          _id
          name
        }
        ticket {
          _id
          title
          ticketId
          status
          ticketType {
            isInternal
          }
        }
      }
    `,
  },
};

export const Report = {
  fragment: {
    InputReportData: gql`
      fragment InputReportData on Report {
        _id
        title
        xAxis {
          value
          label
        }
        table {
          value
          label
        }
        chartType
        segment {
          value
          label
        }
        filters {
          filterType
          field
          condition
          value {
            value
            label
          }
        }
      }
    `,
  },
};

export const ActivityLog = {
  fragments: {
    ActivityLogData: gql`
      fragment ActivityLogData on ActivityLog {
        _id
        resource
        createdAt
        attribute
        actor
        action
        meta
      }
    `,
  },
};

export const AiAssistant = {
  BasicData: gql`
    fragment BasicData on AiAssistant {
      _id
      assistantName
      machine {
        serialNumber
        image
      }
      machineID
      createdBy
    }
  `,
  Documents: gql`
    fragment AiAssistantDocumentsFragment on AiAssistantDocuments {
      externalDocuments {
        ...AiAssistantDocumentFragment
      }
      internalDocuments {
        ...AiAssistantDocumentFragment
      }
    }
    fragment AiAssistantDocumentFragment on AiAssistantDocument {
      _id
      consumedStorage
      externalStorageServiceDocumentID
      status
    }
    fragment AiAssistantDocumentsData on AiAssistant {
      documents {
        ...AiAssistantDocumentsFragment
      }
    }
  `,
  Query: gql`
    fragment AiAssistantQueryResponseFragment on AiAssistantQueryResponse {
      answer
      chat_id
      response_language
      search_results {
        ...AiAssistantQuerySearchResultFragment
      }
      turn_id
    }
    fragment AiAssistantQuerySearchResultFragment on AiAssistantQuerySearchResult {
      document_id
      part_metadata {
        page
      }
      score
      text
    }
  `,
};

export const AssetTemplate = {
  fragments: {
    AssetTemplateData: gql`
      fragment AssetTemplateData on AssetTemplate {
        _id
        templateId
        title
        description
        image
        thumbnail
        createdBy
        oem {
          ...OemBasicData
        }
        assets {
          _id
        }
      }
      ${Oem.fragments.OemBasicData}
    `,
    AssetTemplateFullData: gql`
      fragment AssetTemplateFullData on AssetTemplate {
        _id
        templateId
        title
        description
        image
        thumbnail
        createdBy
        oem {
          ...OemBasicData
        }
        documentFolders {
          internalId
          externalId
        }
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
          }
        }
      }
      ${Oem.fragments.OemBasicData}
    `,
  },
};

export const Asset = {
  fragments: {
    AssetData: gql`
      fragment AssetData on Asset {
        _id
        customer {
          _id
          name
          facilityId
        }
        slug
        name
        serialNumber
        image
        thumbnail
        description
        createdBy
        oem {
          _id
          name
          slug
        }
        template {
          _id
          title
          image
          thumbnail
        }
        _3dModelUrl
        _3dModelUrlUploadedBy {
          _id
          name
        }
        detachedFromTemplate {
          image
          description
          inventoryParts
        }
      }
    `,
    AssetFullData: gql`
      fragment AssetFullData on Asset {
        _id
        slug
        oem {
          _id
          name
          slug
          statuses {
            _id
            label
            color
          }
          logo
          thumbnail
          slug
        }
        customer {
          _id
          name
          facilityId
        }
        name
        detachedFromTemplate {
          image
          description
          inventoryParts
          documentation
        }
        folderId
        serialNumber
        isBoxFoldersDisabled
        isQRCodeEnabled
        generalAccessUrl
        customFields {
          _id
          fieldId {
            _id
            type
            label
            fieldType
            isAdditionalField
            enabled
            created_at
            order
            options {
              _id
              value
              color
            }
          }
          value
        }
        image
        thumbnail
        description
        template {
          _id
          title
          image
          thumbnail
          description
          documentFolders {
            internalId
            externalId
          }
        }
        createdAt
        createdBy
        documentFolders {
          internalId
          externalId
        }
        teams {
          _id
          name
          teamColor
        }
        _3dModelUrl
        _3dModelUrlUploadedBy {
          _id
          name
        }
        aiAssistant {
          _id
          assistantName
        }
        assetType
        hierarchy {
          id
          name
        }
        childrenCount
        totalChildrenCount
        showParent
      }
    `,
    AssetPartsData: gql`
      fragment AssetPartsData on Asset {
        _id
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
            oem {
              _id
              name
            }
            customFields {
              _id
              fieldId {
                _id
                type
                label
                fieldType
                isAdditionalField
                enabled
                created_at
                order
                options {
                  _id
                  value
                  color
                }
              }
              value
            }
          }
        }
      }
    `,
    AssetListData: gql`
      fragment AssetListData on Asset {
        _id
        name
        serialNumber
        image
        createdAt
        updatedAt
        thumbnail
        generalAccessUrl
        isQRCodeEnabled
        teams {
          _id
          name
          teamColor
        }
        customer {
          _id
          name
        }
        customFields {
          _id
          fieldId {
            _id
            type
            label
            fieldType
            isAdditionalField
            enabled
            created_at
            order
            options {
              _id
              value
              color
            }
          }
          value
        }
        createdBy
        template {
          _id
          title
          image
          thumbnail
        }
        oem {
          _id
          logo
          thumbnail
        }
        _3dModelUrlUploadedBy {
          _id
          name
        }
        isAsset3dModelDeletable
        assetType
        hierarchy {
          id
          name
        }
        childrenCount
        totalChildrenCount
      }
    `,
    AssetSearchListData: gql`
      fragment AssetSearchListData on Asset {
        _id
        name
        hierarchy {
          id
          name
        }
        serialNumber
        image
      }
    `,
  },
};
